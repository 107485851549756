.login-container {
    width: 300px;
    height: 230px;
    padding: 10px;
    margin-top: 200px;
    margin-left:auto;
    margin-right: auto;
    display: block;
    justify-content: left;
    align-items: left;
    background-color: #f5f5f5;
    border: solid 1px #e6e6e6;
    border-radius: 25px;
}

.userinput {
  margin: 15px 0px 5px 0px;
  width: 300px;
}

.passinput {
  margin: 10px 0px 5px 0px;
  width: 300px;
}

.passwordforgotButton {
  text-decoration: underline;
  margin-top: 15px;
}

.loginButtonDiv {
    width: 100%;
    height: 30px;
    margin-top: 10px;
    color: white;
    border: none;
    text-align: center;
    cursor: pointer;
}

.loginButtonDiv button {
    width: 100%;
    height: 100%;
    color: white;
    border: none;
    cursor: pointer;
}

.loginnotification {
  width: 300px;
  height: 50px;
  text-align: center;
  font-size: medium;
}