.exportPdf {
    display: block;
    padding: 20px 40px;
    height: 80vh;
    width: 360px;
    margin-left: 5px !important;
    font-size: 0.9em !important;
}

.inputPaginas {
    display: inline-block;
}

.exportradio:checked {
    border-color: #b51e26ce;
    background-color: #b51e26ce;
}

.exportbutton {
    color:white !important;
    border-color: #b51e26ce;
    background-color: #b51e26ce !important;
    min-width: 100px;
}

.downloadbutton {
    color:white !important;
    border-color: #b51e26ce;
    background-color: #b51e26ce !important;
    min-width: 160px;
}

.exportPdfHidden {
    display: none;
}

.subfolderchk {
    margin-left: 20px;
}

.k-checkbox:checked, .k-checkbox.k-checked {
    border-color: #b51e26ce;
    background-color: #b51e26ce;
}

.progressdiv {
    height: 30px;
}

.k-selected {
    background-color: #b51e26ce !important;
}

.pageinput .k-input-inner {
    text-align: right !important;
}

.k-radio {
  border-color: rgba(0, 0, 0, 0.8);
}

.k-radio:disabled {
  opacity: 0.2;
}

a:link {text-decoration: underline; color: #b51e26ce; font-size: 1.3em;}
a:visited {text-decoration: underline; color: #b51e26ce; font-size: 1.3em;}
a:hover {text-decoration: underline; color: #ff0000a9; font-size: 1.3em;}
a:active {text-decoration: underline;color: #000000; font-size: 1.3em;}
