.talktousdiv {
    margin: 20px;
    display: block;
    justify-content: left;
    align-items: left;
}

.nametextbox, .phonetextbox, .emailtextbox {
  margin-top: 15px;
}

.messagetextbox {
  margin-top: 15px;
  height: 200px;
}

.talktousbuttondiv {
  margin-top: 15px;  
  float: right;
}

.talktousbutton {
  color:white !important;
  background-color: #b51e26ce !important;
  padding: 5px 15px;
  margin-left: 2px;
}

.recaptchadiv {
  padding: 10px 0px 10px 40px;
}

