$topBoxes: 87px;
$topBoxes1366: 86px;

.componentDiv { 
    width: 100vw;
    height: 90vh;
    background-color: whitesmoke;
    text-align: center;
    margin: auto;
}

.parentDiv, .parentDiv2 {
    width: 100vw;
    height: auto;
    position: relative;
    overflow: hidden;
    background-color: whitesmoke;    
}

.parentDiv {
    cursor: grab !important;
}

.imgDocument {
    display: block;
    margin-left:auto;
    margin-right:auto;
    z-index: 5000;
}

.imgDraw {
    min-Height: 90vh;
    max-Height: 90vh;
    width: 100%;
    object-fit: contain;
    image-rendering: optimizeQuality;
}

.transform-component-module_wrapper__SPB86 {
    width: 100% !important;
}

.backButtonNavDash, .nextButtonNavDash {
    display: block;
    z-index: 6000;
    background-repeat: no-repeat;
    background-size: cover;    
    width: 42px;
    height: 145px;
    position:fixed;
    top:45%;
}

.backButtonNavDash {    
    float: left;
    margin-left: 1px;        
}

.nextButtonNavDash {    
    float: right;    
    right: 1px; 
}

.right-align {
    margin-left: auto; 
}

.closeButton {     
    color: black;
}