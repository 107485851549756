#cacheImg { opacity: 0;}

.maindiv {
  background-color: #e0e0e0;
}

.error {
    background-color: whitesmoke;
    position: absolute;
    padding: 30px 60px;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    font-size: larger;
}

.leftpanel {
    position: fixed; 
    top: 92px;
    display: block;
    z-index: 10;
    opacity: 1;
    background-color: whitesmoke;
    min-height: 80vh;
    max-height: 85vh;
    overflow-y: hidden !important;
    overflow-x: hidden !important;    
    border: 1px solid #cdcdcd;
    width: 450px;
}

.leftpanelHeader {    
    display: block;
    width: 100%;
    min-height: 35px; 
    border-bottom: 1px solid #cdcdcd;
    color: #333333;
    background-color: #e6e6e6;
    background-image: linear-gradient(#fafafa, #e6e6e6);
}

.leftpanelTitle {
    padding: 10px;
    float: left;
    color:black;
    font-size: 1em;
}

.leftpanelClose {
    float: right;
    padding: 6px;
    cursor: pointer;
}

.closeSvgIcon {
    width: 22px;
}

.leftpanelFilter {
    display: block;
    float: right;
    margin-Top: 2px;
    cursor: pointer;
 }

 .leftpanelFilterIcon {
    margin-Right: 3px
 }

 .leftpanelFilterInput {
    border: 2px solid #ccc;
    box-Shadow: inset 0px 0px 0.5px 0px;
    width: 170px;
    height: 30px;
    margin-Right: 10px;
    bottom: 0;
 }

.shareButtonsDiv {
    position: fixed;
    top: 75%;
    left: 0px;
    width: 10px;
    display: block;
    float:right;
    margin-top: 0px;
    padding-top: 12px;    
    margin-left: 10px;
    cursor: pointer;
}

.shareButtonDiv {
  margin: 6px 2px;
  width: 24px;
  height: 24px;
}

.shareSvgIcon {
  width: 22px;
}

.k-drawer-item .k-icon {
  margin: 0 2px;
  font-size: 28px;
}

.k-drawer-item.k-selected {
  color: #0E0E0E !important;
  background-color: #EFEFEF !important;
}

.k-drawer-item.k-selected:hover {
  color: #0E0E0E !important;
  background-color: #b51e26ce !important;
}

.k-drawer-item.k-selected:first-child:hover {
  color: #0E0E0E !important;
  background-color: #AFAFAF !important;
}

.k-svg-icon > svg {
  fill: black;
}

.messageboxdiv {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 135px;
}

// *** Mobile ***
.leftpanel-mobile {
    top: 0px;
    position: fixed; 
    display: block;
    z-index: 200;
    opacity: 1;
    background-color: whitesmoke;
    min-height: 80vh;
    max-height: 85vh;
    overflow-y: hidden !important;
    overflow-x: hidden !important;    
    border: 1px solid #cdcdcd;   
}

.leftpanelHeader-mobile {
    padding: 2px; 
    display: block;
    width: 100%;
    height: 36px; 
    border-bottom: 1px solid #cdcdcd;
    color: #333333;
    background-color: #e6e6e6;
    background-image: linear-gradient(#fafafa, #e6e6e6);
}

.leftpanelTitle-mobile {
    padding: 10px 4px 4px 4px;
    float: left;
    color:black;
    font-size: 1em;
}

.leftpanelClose-mobile {
    float: right;
    padding: 5px;
    cursor: pointer;
}