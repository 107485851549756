// *** Mobile ***
.footer {
  display: block;
  background-color: #e0e0e0;
  width: 100vw;
  height: 65px;
  min-height: 65px;
}

.infoFooter {
  display: block;
  text-align: center;
  font-size: 1em;
  padding-top: 4px;
  color: white;
}

.clear {
  clear: both;
}

.docprologo-docmulti {        
  display: block;   
  float: right; 
  margin-right: 10px;       
  margin-top: 0.8%;   
  width: 150px;
  height: 48px;    
  z-index: 99999;
  background-repeat: no-repeat !important;
  background-position: right;
  background-image: url('../../public/images/docpro.png');
}