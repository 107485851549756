.docTypePosition-desktop {
  position: fixed;
  bottom: 0;   
  left: 50px;
  z-index: 1000;    
  width: calc(100% - 50px);
  background-color: transparent;
  border-bottom: 1px solid #e0e0e0;
  padding: 0px 0;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}

.transparent {
  background-color: transparent;
}

.maxappbar {
  max-width: 100px;
  background-color: #E6E6E6;
  background-image: linear-gradient(#fafafa, #e6e6e6);
  color:#000;
  border-radius: 10px 10px 0px 0px;
  cursor: pointer;
}

// *** Mobile ***
.docTypePosition-mobile {
  position: fixed;
  bottom: 3px;
  z-index: 200;    
  background-color: transparent;
  margin: 0px 0px 55px 0px;
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  width: 100%;
  left: 0;
}

