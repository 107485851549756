th { border: 0 !important; }

.DocMultiGrid {
    width: 100%;
    height: 90vh !important;
    max-height: 90vh !important;
    overflow-y: auto;
    overflow-x: hidden !important;
}

.bodyMulti {
    width: 100%;
    height: auto !important;  
    max-height: 92vh;
    overflow-y: auto;
    overflow-x: hidden !important;
}

.k-cell-inner > .k-link {
  justify-content: left;
}

.descriptioncolumn, .descriptioncolumnheader {
    cursor: pointer !important;
}

.pagecolumnheader, .matchcolumnheader {
    width: 150px !important;
    text-align: left !important;
    padding-right: 10px !important;
  }

.pagecolumn, .matchcolumn {
  width: 150px !important;
  text-align: right !important;
  padding-right: 20px !important;
}

.filterinput {
    width: 25%;
    display: inline-block;
}

.filterclear {
    display: inline-block;
    width: 4%;
    border: none;
    cursor: pointer;
}

// *** Mobile ***
.DocMultiGrid-mobile {
    width: 100%;
    height: auto !important;  
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden !important;
}

.bodyMulti-mobile {
    width: 100%;
    height: auto !important;  
    max-height: 92vh;
    overflow-y: auto;
    overflow-x: hidden !important;
}

.descriptioncolumn-mobile, .descriptioncolumnheader-mobile {
    min-width: 300px;
    width: 80%;
}

.matchcolumnheader-mobile {
    width: 80px !important;
    text-align: right !important;
}

.matchcolumn-mobile {
    width: 80px !important;
    text-align: right !important;
}

.filterinput-mobile {
    width: 50%;
    display: inline-block;
}

.baseInfoAcervo {
    width: 303px;
    display: block;
    float:right;
}

.blockContent {
    display: block;    
}

.matchstext, .matchsnumber {     
    min-width: 200px !important;
}