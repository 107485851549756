body {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

html,
body {
    &::-webkit-scrollbar {
        display: none;
    }
}

#root, #docpro {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

@import './styles/Bookmarks.scss';
@import './styles/DocMulti.scss';
@import './styles/DocReader.scss';
@import './styles/DocType.scss';
@import './styles/DocumentDraw.scss';
@import './styles/ExportPdf.scss';
@import './styles/FoldersTreeview.scss';
@import './styles/Footer.scss';
@import './styles/Header.scss';
@import './styles/LibraryDetails.scss';
@import './styles/Login.scss';
@import './styles/main.scss';
@import './styles/MenuZoom.scss';
@import './styles/MatchsBox.scss';
@import './styles/PageNavigatorBox.scss';
@import './styles/SearchBox.scss';
@import './styles/Thumbs.scss';
@import './styles/PasswordRecovery.scss';
@import './styles/TalkToUs.scss';
