.bookmarkdiv {
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    min-height: 80vh;
    max-height: 80vh;
    width: 440px;
    float: left;
    font-size: .9em;
}

.topdiv, .bottomdiv {
    display: block;
    padding: 5px;
}

.labelText {
    display: block;
    width: 100%;
    min-height: 20px;
}

.bookmarkgrid {
    max-height: 80vh !important;
    overflow-y: auto;
    overflow-x: hidden !important;
    cursor: pointer;
}
 
.k-grid .k-table-row {
    background-color: #f7f4f3ef !important; // Light gray
}

.k-grid .k-table-row.k-table-alt-row {
    background-color: #e6e6e6 !important; // Dark gray
}

.k-grid th, .k-grid td {
    text-overflow: clip !important;
    white-space: wrap !important;
}