.treeviewdiv {
    float: left;
    overflow-y: auto !important;
    overflow-x: auto !important;
    height: 80vh;
    width: 440px;
}

.k-treeview {
    margin-left: 5px !important; 
    font-size: 1.0em !important;
    text-overflow: clip !important;
    white-space: wrap !important;
}

.k-treeview-leaf.k-selected {
    background-color: #837777;
}

.k-treeview-leaf {
    touch-action: auto  !important;
}

// *** Mobile ***
.treeviewdiv-mobile {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    min-height: 80vh;
    max-height: 90vh;
    min-width: 10vh;
    max-width: 45vh;
    float: left;
    padding-right: 60px;
}

.k-treeview-mobile {
    max-width: 95vw !important;
    min-width: 25vw !important;
    margin-left: 5px !important; 
    font-size: 0.75em !important;
    text-overflow: clip !important;
    white-space: wrap !important;
}
